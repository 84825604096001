import React, { useState, useEffect, useRef } from 'react';
import { useLanyard } from "react-use-lanyard";
import './SpotifyStatus.css'

const DISCORD_ID = "391531473259462656";

const SpotifyStatus: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [spotifyData, setSpotifyData] = useState<any>(null);
  const titleRef = useRef<HTMLDivElement>(null);
  const [isMarquee, setIsMarquee] = useState(false);

  const { loading, status } = useLanyard({
    userId: DISCORD_ID,
    socket: true,
  });

  useEffect(() => {
    if (status) {
      setSpotifyData(status.spotify);
      setIsLoading(false);
    }
  }, [status]);

  useEffect(() => {
    if (titleRef.current) {
      setIsMarquee(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [spotifyData]);

  return (
    <div className="bg-gray-800 p-3 rounded-lg text-white min-w-[350px] max-w-[350px]">
      {isLoading ? (
        <div>Loading...</div>
      ) : spotifyData ? (
        <div className="flex flex-col">
          <div className="flex items-center space-x-2 mb-3">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-glow"></div>
            <span className="text-sm text-gray-300">Listening to Spotify</span>
          </div>
          <div className="flex items-end space-x-3">
            <img src={spotifyData.album_art_url} alt="Album Art" className="w-12 h-12 rounded" />
            <div className="flex-grow flex items-end justify-between overflow-hidden">
              <div className="flex flex-col overflow-hidden">
                <div className="marquee-container">
                  <div ref={titleRef} className={`font-medium whitespace-nowrap ${isMarquee ? 'marquee' : ''}`}>
                    {spotifyData.song}
                  </div>
                </div>
                <p className="text-sm text-gray-300">by {spotifyData.artist}</p>
              </div>
              <a
                href={`https://open.spotify.com/track/${spotifyData.track_id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="flex-shrink-0 ml-2"
              >
                <svg className="w-5 h-5 text-green-500" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center space-x-3">
          <div className="w-12 h-12 bg-gray-600 rounded flex items-center justify-center">
            ♪
          </div>
          <p>Not listening to anything right now!</p>
        </div>
      )}
    </div>
  );
};

export default SpotifyStatus;