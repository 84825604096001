import React from 'react';
import SpotifyStatus from './SpotifyStatus';

const App: React.FC = () => {
  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <header className="p-4">
        <nav className="flex justify-between items-center">
          <span>🌐</span>
          <ul className="flex space-x-4">
            <li>about</li>
            <li>projects</li>
            <li>...</li>
          </ul>
        </nav>
      </header>
      
      <main className="flex-grow flex flex-col items-center justify-center">
        <h1 className="text-4xl font-bold mb-2">Kevin Chen</h1>
        <p className="mb-4">23-year-old developer from Brooklyn.</p>
        <div className="flex space-x-4 mb-8">
          <span>GH</span>
          <span>IN</span>
          <span>✉️</span>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg flex items-center space-x-2">
          <SpotifyStatus />
        </div>
      </main>
    </div>
  );
};

export default App;